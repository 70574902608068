import Cookies from 'universal-cookie';
// import cookie from 'react-cookies'

export const cookies = new Cookies();

export const CRM_ACCESS_TOKEN_COOKIE_NAME = `${process.env.REACT_APP_SERVICE_CODE}_STORE_API_ACCESS_TOKEN`

export const CRM_ACCESS_TOKEN_COOKIE_EXPIRES_IN = `${process.env.REACT_APP_SERVICE_CODE}_STORE_API_ACCESS_TOKEN_EXPIRES_IN`

export const save_crm_access_token = (token) => {
    cookies.set(
        CRM_ACCESS_TOKEN_COOKIE_NAME,
        `${token.token_type} ${token.access_token}`,
        { path: '/', maxAge: token.expires_in, sameSite: 'none', secure: true}
    )
    cookies.set(
        CRM_ACCESS_TOKEN_COOKIE_EXPIRES_IN,
        token.expires_in,
        { path: '/', maxAge: token.expires_in-60, sameSite: 'none', secure: true}
    )
    console.log(cookies)
    return true
}

export const clear_login_cookie = () => {
    cookies.remove(CRM_ACCESS_TOKEN_COOKIE_NAME, { path: '/', sameSite: 'none', secure: true})
    return true
}

export const check_crm_access_token = () => {
    let access_token = cookies.get(CRM_ACCESS_TOKEN_COOKIE_NAME)
    return access_token ? access_token : null
}

export const check_crm_access_token_expires_in = () => {
    let expires_in = cookies.get(CRM_ACCESS_TOKEN_COOKIE_EXPIRES_IN)
    return (expires_in && parseInt(expires_in)) || 0
}

export const getCurrentCountry = () => {
    var segments = window.location.hostname.split(".");
    var tld = segments[segments.length - 1];
    if (tld.toLowerCase() === "com")
        tld = "us";
    return tld
}

export const selcetCountry = () => {
    const country = getCurrentCountry()
    switch (country) {
        case "de":
            return "de"
        case "us":
            return "us"
        case "es":
            return "es"
        case "it":
            return "it"
        case "uk":
            return "uk"
        case "at":
            return "at"
        case "pl":
            return "pl"
        default:
            return "de"
    }
}

export const getBrand = () => {
    const country = getCurrentCountry()
    switch (country) {
        case "de":
            return "brillen.de"
        case "us":
            return "brillen.com"
        case "es":
            return "gafas.es"
        case "it":
            return "occhiali24.it"
        case "uk":
            return "brillen.co.uk"
        case "at":
            return "brillen.at"
        case "pl":
            return "brillen.pl"
        default:
            return "brillen.at"
    }

}

export const loadLogo = () => {
    const country = getCurrentCountry()
    switch (country) {
        case "de":
            return "https://www.brillen.de/hs-fs/hubfs/brillen-DE-logo-xmas.png?width=250&name=brillen-DE-logo-xmas.png"
        case "us":
            return "https://www.brillen.com/hs-fs/hubfs/brillen-COM-logo-xmas.png?width=300&name=brillen-COM-logo-xmas.png"
        case "es":
            return "https://www.gafas.es/hs-fs/hubfs/gafas-ES-logo-xmas.png?width=274&name=gafas-ES-logo-xmas.png"
        case "it":
            return "occhiali24.it"
        case "uk":
            return "https://www.brillen.co.uk/hubfs/brillen-co-uk_Logo%20c100-1.svg"
        case "at":
            return "brillen.at"
        case "pl":
            return "brillen.pl"
        default:
            return "https://www.brillen.de/hs-fs/hubfs/brillen-DE-logo-xmas.png?width=250&name=brillen-DE-logo-xmas.png"
    }

}


