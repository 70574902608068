import { green, red, yellow } from "@material-ui/core/colors";

import CircularProgress from "@material-ui/core/CircularProgress";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MoodBadIcon from "@material-ui/icons/MoodBad";
import React from "react";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    padding: "0.5% 0",
    borderBottom: "1px solid #5dc11f",
    position: "relative",
  },

  wsState: {
    marginLeft: theme.spacing(1),
    position: "absolute",
    bottom: 0,
    color: "white",
    width: "100%",
    height: "100%",

    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",

    fontSize: "1.5vw",
  },

  connected: {
    color: green[500],
  },

  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },

  connecting: {
    color: yellow[500],
  },

  moodBad: {
    color: red[500],
  },

  connectingLoading: {
    color: yellow[500],
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1,
  },

  heaherAction: {
    position: "absolute",
    bottom: 0,
    color: "white",
    width: "100%",
    height: "100%",

    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",

    fontSize: "1.5vw",
  },

  logo: {
    height: "3vw",
  },

  exitButtom: {
    color: "white",
    fontSize: "2.5vw",
    margin: "0 10px",
    cursor: "pointer",
  },
});
class Header extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <>
        <div className={classes.root}>
          <div
            className={`
            ${classes.wsState} 
            ${this.props.wsState === "connected" ? classes.connected : ""}
            ${this.props.wsState === "connecting" ? classes.connecting : ""}
            ${this.props.wsState === "closed" ? classes.moodBad : ""}`}
          >
            {this.props.wsState === "connected" ? (
              <VerifiedUserIcon />
            ) : (
              <div className={classes.wrapper}>
                <MoodBadIcon className={classes.moodBad} />
                {this.props.wsState === "connecting" ? (
                  <CircularProgress
                    size={24}
                    className={classes.connectingLoading}
                  />
                ) : null}
              </div>
            )}
            <span>{this.props.wsState}</span>
          </div>
          <div>
            <img
              className={classes.logo}
              src={process.env.REACT_APP_LOGO}
              alt=""
            />
          </div>

          {this.props.loginState && !this.props.inVideo ? (
            <div className={classes.heaherAction}>
              <span>{this.props.storeName}</span>
              <ExitToAppIcon
                className={classes.exitButtom}
                onClick={this.props.onLogout}
              />
            </div>
          ) : null}
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Header);
