import MicIcon from '@material-ui/icons/Mic';
import React from 'react';
import VideocamIcon from "@material-ui/icons/Videocam";
class Appointment extends React.Component {

    generateAppointmentView = () => {
        let viewList = []
        for (let i = 0; i < 15; i++) {
            if (i < this.props.appointmentList.length) {
                let item = this.props.appointmentList[i]
                viewList.push(
                    <tr key={i + 1}>
                        <td>{i + 1}</td>
                        <td>{item.contact_no}</td>
                        <td>{item.appointment_time}</td>
                    </tr>
                )
            } else {
                viewList.push(
                    <tr key={i + 1}>
                        <td>{i + 1}</td>
                        <td>{''}</td>
                        <td>{''}</td>
                    </tr>
                )
            }
        }
        return viewList
    }

    getDeviceStatus = () => {
        let status = {}
        this.props.deviceList.forEach(device => {
            let deviceType = device.type?.toUpperCase() || null
            let deviceStatus = device.status
            if (deviceType === 'RK' && deviceStatus === 'connect') {
                status['RK'] = true
            } else if (deviceType === 'DR' && deviceStatus === 'connect') {
                status['DR'] = true
            } else if (deviceType === 'LC' && deviceStatus === 'connect') {
                status['LC'] = true
            }
        })
        return status
    }

    render() {
        let deviceStatus = this.getDeviceStatus()
        return (
            <div className={"appointment"}>
                <div className={"appointment-name"}>Appointment</div>
                <table className={"appointment-content"} id={"table-2"}>
                    <tbody>
                        <tr>
                            <th width={"40px"}>ID</th>
                            <th>Customer No.</th>
                            <th>Appt. Time</th>

                        </tr>
                        {this.generateAppointmentView()}
                    </tbody>
                </table>
                <div className={"connect"}>
                    <div className={"connect-status"}>
                        <div className={deviceStatus.RK ? 'connect-true' : 'connect-fales'}>RK</div>
                    </div>
                    <div className={"connect-status"}>
                        <div className={deviceStatus.DR ? 'connect-true' : 'connect-fales'}>DR</div>
                    </div>
                    <div className={"connect-status"}>
                        <div className={deviceStatus.LC ? 'connect-true' : 'connect-fales'}>LC</div>
                    </div>
                    <div className={"connect-status"}>
                        <div className={deviceStatus.LC ? 'connect-true' : 'connect-fales'}>
                            <VideocamIcon style={this.props.detectedCamera? { color: "green" }: { color: "red" }}/>
                        </div>
                    </div>
                    <div className={"connect-status"}>
                        <div className={'connect-fales'}>
                            <MicIcon style={this.props.detectedAudio? { color: "green" }: { color: "red" }}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Appointment;

