import * as BackendApi from "../BackendApi";

import { Grid, Tooltip } from "@material-ui/core";
import { Component as ReactComponent, createRef } from "react";
import {
  connect as connectRoom,
  createLocalAudioTrack,
  createLocalVideoTrack,
} from "twilio-video";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Check from "@material-ui/icons/Check";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import ErrorIcon from "@material-ui/icons/Error";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import IconButton from "@material-ui/core/IconButton";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import RefreshSharpIcon from "@material-ui/icons/RefreshSharp";
import ReplyAllIcon from "@material-ui/icons/ReplyAll";
import Step from "@material-ui/core/Step";
import StepContent from "@material-ui/core/StepContent";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import Typography from "@material-ui/core/Typography";
import VideocamIcon from "@material-ui/icons/Videocam";
import VideocamOffIcon from "@material-ui/icons/VideocamOff";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import clsx from "clsx";
import { withRouter } from "react-router-dom";
import { yellow } from "@material-ui/core/colors";

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const YellowButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(yellow[500]),
    backgroundColor: yellow[500],
    "&:hover": {
      backgroundColor: yellow[700],
    },
  },
}))(Button);

const styles = (theme) => ({
  stepRoot: {
    width: "100%",
  },
  stepButton: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  stepActionsContainer: {
    marginBottom: theme.spacing(2),
  },

  root: {
    display: "flex",
    flexDirection: "column",
  },

  alignCenter: {
    textAlign: "-webkit-center",
  },

  fullHeight: {
    height: "100%",
  },

  fullWidth: {
    width: "100%",
  },

  videoContainer: {},

  videoBlock: {
    position: "relative",
  },

  remoteVideoStatus: {
    position: "absolute",
    zIndex: 10,
    width: "100%",
    textAlign: "left",
  },
  remoteVideo: {
    width: "100%",
  },
  remoteVideoBlock: {
    width: "100%",
    height: "100%",
    backgroundColor: "#dddada85",
    position: "relative",
  },

  remoteLoading: {
    position: "absolute",
    top: "40%",
    left: "50%",
  },

  localVideo: {
    width: "100%",
  },
  localVideoBlock: {
    position: "absolute",
    zIndex: 5,
    right: 0,
    width: "30%",
  },

  videoActionBlock: {
    width: "100%",
    position: "absolute",
    zIndex: 5,
    bottom: 0,
    textAlign: "-webkit-right",

    "&:hover": {
      backgroundColor: "#80808059",
    },
  },
});

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#784af4",
  },
  error: {
    color: "#f44336",
  },
  completed: {
    color: "#75eb3e",
  },
  circle: {
    marginLeft: 8,
    marginRight: 2,
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  check: {
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed, error } = props;
  const getIcon = () => {
    if (error) {
      return <ErrorIcon />;
    }
    if (completed) {
      return <Check className={classes.check} />;
    }
    if (active) {
      return <CircularProgress size={18} />;
    }
    return <div className={classes.circle} />;
  };
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.error]: error,
        [classes.completed]: completed,
      })}
    >
      {getIcon()}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,

  error: PropTypes.bool,
};

class VideoPanel extends ReactComponent {
  constructor(props) {
    super(props);
    this.localVideoRef = createRef();
    this.remoteVideoRef = createRef();
    this.remoteAudioRef = createRef();
    this.waitMonitorRunner = createRef();

    this.videoContainer = createRef();
  }

  _isMounted = false;

  state = {
    isLocalVideoEnable: false,

    isRemoteConnected: false,

    isRemoteVideoEnable: false,
    isRemoteAudioEnable: false,
    isRemoteVideoStarted: false,

    openPreCheck: true,

    stepNames: ["camera", "audio", "room", "join", "invite", "wait"],

    stepLabels: [
      "Request local camera device ...",
      "Request local audio device ...",
      "Creating video room ...",
      "Join room ...",
      "Create task to invite optometrist ...",
      "Waiting for an optometrist ...",
    ],

    stepResults: {
      camera: null,
      audio: null,
      room: null,
      join: null,
      invite: null,
      wait: null,
    },

    checkStep: 0,
    stepError: false,
    stepErrorReason: "",

    openWaitAsk: false,

    videoBlockSize: {
      width: "100%",
      height: "100%",
    },
  };

  componentDidMount() {
    this._isMounted = true;
    console.log("Video Panel Mount", this.props);

    this.videoCheckStart();

    this.videoBlockSize();

    window.addEventListener("resize", this.videoBlockSize);
  }

  componentWillUnmount() {
    this._isMounted = false;
    console.log("Video Panel Unmount", this.props);
    this.endVideo();
    window.removeEventListener("resize", this.videoBlockSize);
  }

  onTrackPublished = (publication, participant) => {
    console.log(
      `RemoteParticipant ${participant.identity} published a RemoteTrack:`,
      publication
    );

    publication.on("subscribed", (track) => {
      console.log(`LocalParticipant subscribed to a RemoteTrack`, track);
      if (track.kind === "video") {
        this.subscribRemoteVideoTrack(track);
      } else if (track.kind === "audio") {
        this.subscribRemoteAudioTrack(track);
      } else if (track.kind === "data") {
        this.subscribRemoteDataTrack(track);
      }
    });

    publication.on("unsubscribed", (track) => {
      console.log(`LocalParticipant unsubscribed from a RemoteTrack: ${track}`);
      if (track.kind === "video") {
        this.unsubscribRemoteVideoTrack(track);
      } else if (track.kind === "audio") {
        this.unsubscribRemoteAudioTrack(track);
      }
    });
  };

  participantConnected = (participant) => {
    console.log(`RemoteParticipant ${participant.identity} connected`);
    participant.tracks.forEach((publication) => {
      this.onTrackPublished(publication, participant);
    });

    participant.on("trackPublished", (publication) => {
      this.onTrackPublished(publication, participant);
    });

    participant.on("trackUnpublished", (publication) => {
      console.log(
        `RemoteParticipant ${participant.identity} unpublished a RemoteTrack: ${publication}`
      );
    });

    if (this._isMounted) {
      this.setState({ isRemoteConnected: true }, () => {
        this.finishCheck();
      });
    }
  };

  participantDisconnected = (participant) => {
    console.log(`RemoteParticipant ${participant.identity} disconnected`);
    if (this._isMounted) {
      this.setState({ isRemoteConnected: false, openPreCheck: true }, () => {
        this.videoCheckStep(this.state.stepNames.length - 1);
      });
    }
  };

  attachAudioTrack = (audioTrack) => {
    console.log("attachAudioTrack", audioTrack);
    this.remoteAudioRef.current = audioTrack.attach();
    document.body.appendChild(this.remoteAudioRef.current);
    console.log("attachAudioDom", this.remoteAudioRef.current);
  };

  detachAudioTrack = (audioTrack) => {
    console.log("detachAudioTrack", audioTrack);
    audioTrack?.detach()?.forEach((el) => el.remove());
  };

  attachVideoTrack = (videoTrack, el) => {
    console.log("attachVideoTrack", videoTrack, el);
    const isFrontFacing =
      videoTrack.mediaStreamTrack?.getSettings().facingMode !== "environment";
    let trackDom = videoTrack.attach(el);
    if (isFrontFacing) {
      trackDom.style.transform = "rotateY(180deg)";
    }
    console.log("attachVideoDom", trackDom);
  };

  detachVideoTrack = (videoTrack, el) => {
    console.log("detachVideoTrack", videoTrack);
    videoTrack.detach(el);
  };

  subscribRemoteAudioTrack = (audioTrack) => {
    console.log("subscribRemoteAudioTrack", audioTrack);
    this.attachAudioTrack(audioTrack);
    if (this._isMounted) {
      this.setState({ isRemoteAudioEnable: true });
    }
  };

  unsubscribRemoteAudioTrack = (audioTrack) => {
    console.log("unsubscribRemoteAudioTrack", audioTrack);
    if (this._isMounted) {
      this.setState({ isRemoteAudioEnable: false });
    }
    this.detachAudioTrack(audioTrack);
  };

  subscribRemoteVideoTrack = (videoTrack) => {
    console.log("subscribRemoteVideoTrack", videoTrack);
    videoTrack.on("started", (track) => {
      if (this._isMounted) {
        this.setState({ isRemoteVideoStarted: true });
      }
      console.log("RemoteVideoTrack started", track);
    });
    videoTrack.on("disabled", (track) => {
      console.log("RemoteVideoTrack disabled => paused", track);
    });
    videoTrack.on("enabled", (track) => {
      console.log("RemoteVideoTrack enabled => resumed", track);
    });
    videoTrack.on("switchedOff", (track) => {
      console.log("RemoteVideoTrack switchedOff", track);
    });
    videoTrack.on("switchedOn", (track) => {
      console.log("RemoteVideoTrack switchedOn", track);
    });
    this.attachVideoTrack(videoTrack, this.remoteVideoRef.current);
    if (this._isMounted) {
      this.setState({ isRemoteVideoEnable: true });
    }
  };

  unsubscribRemoteVideoTrack = (videoTrack) => {
    console.log("unsubscribRemoteVideoTrack", videoTrack);
    if (this._isMounted) {
      this.setState({
        isRemoteVideoEnable: false,
        isRemoteVideoStarted: false,
      });
    }
    this.detachVideoTrack(videoTrack, this.remoteVideoRef.current);
  };

  attachLocalVideoTrack = (videoTrack) => {
    console.log("attachLocalVideoTrack", videoTrack);
    this.attachVideoTrack(videoTrack, this.localVideoRef.current);
    if (this._isMounted) {
      this.setState({ isLocalVideoEnable: true });
    }
  };

  detachLocalVideoTrack = (videoTrack) => {
    console.log("detachLocalVideoTrack", videoTrack);
    if (this._isMounted) {
      this.setState({ isLocalVideoEnable: false });
    }
    this.detachVideoTrack(videoTrack, this.localVideoRef.current);
  };

  subscribRemoteDataTrack = (dataTrack) => {
    dataTrack.on("message", (message) => {
      console.log(`Receive Data:`, message);
      let temp;
      try {
        temp = JSON.parse(message);
      } catch (error) {
        temp = null;
      }
      temp?.type === "command" && this.receiveCommand(temp.value);
      temp?.type === "message" && this.receiveMessage(temp.value);
    });
  };

  receiveMessage = (message) => {
    console.log(`Receive Message:`, message);
  };

  receiveCommand = (command) => {
    console.log(`Receive Command:`, command);
    if (command === "refresh") {
      this.reload();
    } else if (command === "end-video") {
      this.endVideo();
    }
  };

  setupRoom = (room) => {
    // this.setState({ room: room });
    room.on("disconnected", (room) => {
      // Detach the local media elements
      console.log("Leave Room: ", room);
      room.localParticipant.tracks.forEach((publication) => {
        publication.unpublish();
        if (publication.track.kind === "video") {
          this.detachLocalVideoTrack(publication.track);
        }
      });

      if (this._isMounted) {
        this.setState({ isRemoteConnected: false });
      }
    });
    // add local tracks
    room.localParticipant.videoTracks.forEach((publication) => {
      if (publication.track) {
        this.attachLocalVideoTrack(publication.track);
      }
    });

    // 房间中已经在等待的验光师
    room.participants.forEach(this.participantConnected);
    room.on("participantConnected", this.participantConnected);
    room.on("participantDisconnected", this.participantDisconnected);
  };

  closeLocalDevice = () => {
    console.log("Close local camera");
    this.state.stepResults?.camera?.stop();
    console.log("Close local audio");
    this.state.stepResults?.audio?.stop();
  };

  disconnectFromRoom = () => {
    console.log("Disconnect from room");
    this.state.stepResults.join?.disconnect();
  };

  connectCamera = async () => {
    try {
      let videoTrack = await createLocalVideoTrack({ width: 320 });
      if (this._isMounted) {
        console.log("Connect to camera success.");
        return videoTrack;
      } else {
        console.warn("Connect to camera after Unmounted", videoTrack);
        videoTrack.stop();
        // eslint-disable-next-line
        throw {
          name: "AfterMount",
          message:
            "Connect to camera after page mount, please refresh this page to try again.",
        };
      }
    } catch (error) {
      console.error("Connect to camera failed.");
      console.table(error);
      if (error?.name === "AfterMount") {
        throw error;
      }
      // eslint-disable-next-line
      throw {
        name: "CameraException",
        message:
          "Unable to open the camera, please check your camera device or camera permissions on this page.",
      };
    }
  };

  connectAudio = async () => {
    try {
      let audioTrack = await createLocalAudioTrack();
      if (this._isMounted) {
        console.log("Connect to audio success.");
        return audioTrack;
      } else {
        console.warn("Connect to audio after Unmounted", audioTrack);
        audioTrack.stop();
        // eslint-disable-next-line
        throw {
          name: "AfterMount",
          message:
            "Connect to audio after page mount, please refresh this page to try again.",
        };
      }
    } catch (error) {
      console.error("Connect to audio failed.");
      console.table(error);
      if (error?.name === "AfterMount") {
        throw error;
      }
      // eslint-disable-next-line
      throw {
        name: "AudioException",
        message:
          "Unable to open the audio, please check your audio device or audio permissions on this page.",
      };
    }
  };

  createVideoRoom = async () => {
    try {
      let apiResult = await BackendApi.getvideo(this.props.videoSession);
      if (apiResult.success) {
        console.log("Create video room success.");
        return apiResult;
      } else {
        // eslint-disable-next-line
        throw {
          name: "BackendServiceError",
          code: apiResult.code,
          message: apiResult.failed_reason || "",
        };
      }
    } catch (error) {
      console.error("Create video room failed.");
      console.table(error);
      if (error?.name === "BackendServiceError" && error?.code === 1404) {
        // eslint-disable-next-line
        throw {
          name: "SessionNotFound",
          message: "Create video room failed, this session is not exists.",
        };
      } else if (
        error?.name === "BackendServiceError" &&
        (error?.code === 1504 || error?.code === 1503)
      ) {
        // eslint-disable-next-line
        throw {
          name: "BackendConfigError",
          message:
            "An error has occurred in backend service, please contact the IT support.",
        };
      } else if (
        error?.name === "BackendServiceError" &&
        (error?.code <= 999 || error?.code > 10000)
      ) {
        // eslint-disable-next-line
        throw {
          name: "VideoServiceError",
          message:
            "Video service is not available now, please contact the IT support or try again later.",
        };
      } else if (error?.code === 500) {
        // eslint-disable-next-line
        throw {
          name: "BackendTimeout",
          message: "Backend service is busy, please try again later.",
        };
      }
      // eslint-disable-next-line
      throw {
        name: "ApiException",
        message:
          "Create video room failed, please checkout your network and try again later.",
      };
    }
  };

  joinRoom = async () => {
    try {
      let room = await connectRoom(this.state.stepResults.room.access_token, {
        name: this.state.stepResults.room.room_sid,
        tracks: [this.state.stepResults.camera, this.state.stepResults.audio],
      });
      if (this._isMounted) {
        console.log("Connect To Video Room:", room);
        this.setupRoom(room);
        return room;
      } else {
        console.warn("Connect To Video Room After Unmounted", room);
        room.disconnect();
        // eslint-disable-next-line
        throw {
          name: "AfterMount",
          message:
            "Join room after page mount, please refresh this page to try again.",
        };
      }
    } catch (error) {
      console.error("Join room failed");
      console.table(error);
      if (error?.name === "AfterMount") {
        throw error;
      }
      // eslint-disable-next-line
      throw {
        name: "JoinRoomFailed",
        message:
          "Join room failed, an error has occurred when connect to room, please try again later.",
      };
    }
  };

  inviteOptometrist = async (newTask) => {
    try {
      let apiResult = await BackendApi.invite_optometrist({
        session_id: this.props.videoSession,
        room_name: this.state.stepResults.join?.name,
        new_task: newTask ? true : false,
      });
      if (apiResult.success) {
        console.log("Invite task is created. New Task: ", newTask);
        return apiResult;
      } else {
        // eslint-disable-next-line
        throw {
          name: "BackendServiceError",
          code: apiResult.code,
          message: apiResult.failed_reason || "",
        };
      }
    } catch (error) {
      console.error("Create invite task failed. New Task: ", newTask);
      console.table(error);
      if (error?.name === "BackendServiceError" && error?.code === 1404) {
        // eslint-disable-next-line
        throw {
          name: "SessionNotFound",
          message: "Create invite task failed, this session is not exists.",
        };
      } else if (
        error?.name === "BackendServiceError" &&
        (error?.code === 1504 || error?.code === 1503)
      ) {
        // eslint-disable-next-line
        throw {
          name: "BackendConfigError",
          message:
            "An error has occurred in backend service, please contact the IT support.",
        };
      } else if (
        error?.name === "BackendServiceError" &&
        (error?.code <= 999 || error?.code > 10000)
      ) {
        // eslint-disable-next-line
        throw {
          name: "TaskServiceError",
          message:
            "Task service is not available now, please contact the IT support or try again later.",
        };
      } else if (error?.code === 500) {
        // eslint-disable-next-line
        throw {
          name: "BackendTimeout",
          message: "Backend service is busy, please try again later.",
        };
      }
      // eslint-disable-next-line
      throw {
        name: "ApiException",
        message:
          "Create invite task failed, please checkout your network and try again later.",
      };
    }
  };

  waitMonitor = async () => {
    this.clearWaitMonitor();
    console.log("Start to monitor waiting");
    this.waitMonitorRunner.current = setTimeout(() => {
      if (!this.state.isRemoteConnected) {
        this.setState({ openWaitAsk: true });
      }
    }, 60000);
  };

  clearWaitMonitor = async () => {
    if (this.waitMonitorRunner.current) {
      clearTimeout(this.waitMonitorRunner.current);
    }
  };

  getCheckItem = (name) => {
    // ["camera", "audio", "room", "join", "invite"],
    switch (name) {
      case "camera":
        return {
          onEnter: this.connectCamera,
        };
      case "audio":
        return {
          onEnter: this.connectAudio,
        };
      case "room":
        return {
          onEnter: this.createVideoRoom,
        };
      case "join":
        return {
          onEnter: this.joinRoom,
        };
      case "invite":
        return {
          onEnter: this.inviteOptometrist,
        };
      case "wait":
        return {
          onEnter: this.waitMonitor,
        };
      default:
        return null;
    }
  };

  videoCheckStep = async (step, args) => {
    // console.log("stepResults", this.state.stepResults);
    if (step < 0 || step > (this.state.stepNames?.length || 0)) {
      console.warn(`Video check step ${step + 1} is out of allowed steps`);
      return false;
    }
    if (!this.state.openPreCheck || !this._isMounted) {
      console.warn(`Video check step ${step + 1} is runned while unmount`);
      return false;
    }
    this.setState({ checkStep: step, stepError: false, stepErrorReason: "" });
    let stepName = this.state.stepNames[step];
    console.log(`Start to check step ${step + 1}:${stepName}`);
    let checkItem = this.getCheckItem(stepName);
    if (!checkItem) {
      return false;
    }
    try {
      let stepResult = null;
      if (checkItem.onEnter) {
        if(args && args.length){
          stepResult = await checkItem.onEnter(...args);
        }else{
          stepResult = await checkItem.onEnter();
        }
      } else {
        console.warn(
          `Not found enter handler in check step ${step + 1}:${stepName}.`
        );
      }
      console.log(`Check step ${step + 1}:${stepName} run success.`);
      if (this._isMounted) {
        this.setState(
          {
            stepResults: { ...this.state.stepResults, [stepName]: stepResult },
          },
          () => {
            if (step < (this.state.stepNames?.length || 0) - 1) {
              // Trigger next step
              this.videoCheckStep(step + 1);
            } else {
              console.log("Finish all of the check steps");
            }
          }
        );
      } else {
        console.warn(
          `Check step ${step + 1}:${stepName} run success after mount.`
        );
      }
    } catch (error) {
      console.log(`Check step ${step + 1}:${stepName} run failed.`);
      console.table(error);
      if (error?.name === "AfterMount") {
        this.closeLocalDevice();
        this.disconnectFromRoom();
      }
      if (this._isMounted) {
        this.setState({
          stepError: true,
          stepErrorReason: error?.message || "",
        });
      } else {
        console.warn(
          `Check step ${step + 1}:${stepName} run failed after mount.`
        );
      }
    }
  };

  closeWaitAsk = () => {
    this.setState({ openWaitAsk: false });
  };

  continueWaitAsk = () => {
    this.waitMonitor();
    this.closeWaitAsk();
  };

  finishWaitAsk = () => {
    this.clearWaitMonitor();
    this.closeWaitAsk();
  };

  closeCheck = () => {
    this.state.openPreCheck = false;
    this.setState({ openPreCheck: false });
  };

  finishCheck = () => {
    this.finishWaitAsk();
    this.closeCheck();
  };

  stopCheck = () => {
    this.closeLocalDevice();
    this.disconnectFromRoom();
    this.finishCheck();
  };

  videoCheckStart = () => {
    console.log("Start to run video check steps");
    this.setState({ openPreCheck: true, checkStep: 0 }, () => {
      this.videoCheckStep(0);
    });
  };

  changeOptometrist = () => {
    this.closeWaitAsk();
    this.videoCheckStep(4, [true])
  };

  endVideo = () => {
    this.stopCheck();
    this.props.endVideo();
  };

  reload = () => {
    this.stopCheck();
    this.videoCheckStart();
  };

  generateWaitAskDialog = () => {
    return (
      <Dialog
        aria-labelledby="wait-ask-dialog-title"
        open={this.state.openWaitAsk}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <MuiDialogTitle id="wait-ask-dialog-title">
          Whether to continue waiting?
        </MuiDialogTitle>
        <DialogContent dividers>
          <Typography>
            No optometrist answered within 1 minutes, whether to continue
            waiting?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={this.endVideo}>
            Cancel
          </Button>
          <YellowButton variant="contained" onClick={this.changeOptometrist}>
            Another optometrist
          </YellowButton>
          <Button
            variant="contained"
            color="primary"
            onClick={this.continueWaitAsk}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  generateCheckSteps = () => {
    const { classes } = this.props;
    return (
      <div className={classes.stepRoot}>
        <Stepper activeStep={this.state.checkStep} orientation="vertical">
          {this.state.stepNames.map((name, index) => {
            let currentStepError =
              (this.state.checkStep === index && this.state.stepError) || false;
            let currentStepErrorReason =
              (this.state.checkStep === index && this.state.stepErrorReason) ||
              "";
            return (
              <Step key={name}>
                <StepLabel
                  error={currentStepError}
                  StepIconComponent={QontoStepIcon}
                >
                  {this.state.stepLabels[index]}
                </StepLabel>
                {currentStepError && currentStepErrorReason ? (
                  <StepContent>
                    <Typography color="error">
                      {currentStepErrorReason}
                    </Typography>
                    <div className={classes.stepActionsContainer}>
                      <div>
                        <Button
                          size="small"
                          color="primary"
                          variant="contained"
                          onClick={() => this.videoCheckStep(index)}
                          className={classes.stepButton}
                        >
                          Retry
                        </Button>
                      </div>
                    </div>
                  </StepContent>
                ) : null}
              </Step>
            );
          })}
        </Stepper>
      </div>
    );
  };

  generateCheckDialog = () => {
    return (
      <Dialog
        aria-labelledby="check-dialog-title"
        open={this.state.openPreCheck}
        maxWidth="sm"
        fullWidth
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="check-dialog-title" onClose={this.endVideo}>
          Checking
        </DialogTitle>
        <DialogContent dividers>{this.generateCheckSteps()}</DialogContent>
      </Dialog>
    );
  };

  videoBlockSize = () => {
    let partnerWidth = this.videoContainer.current?.scrollWidth | 0;
    let partnerHeight = this.videoContainer.current?.scrollHeight | 0;
    let width = partnerWidth <= partnerHeight ? partnerWidth : partnerHeight;
    let height = (width / 4) * 3;
    console.log("videoBlockSize", width);
    this.setState({ videoBlockSize: { width: width, height: height } });
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        {this.generateCheckDialog()}
        {this.generateWaitAskDialog()}
        <div
          className={`${classes.root} ${classes.fullHeight} ${classes.alignCenter}`}
        >
          <div style={{ height: "4vw" }} />
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div style={{ width: "2vw" }} />
            <div
              className={`${classes.alignCenter} ${classes.fullWidth} ${classes.fullHeight}`}
              style={{ flex: 1 }}
              ref={this.videoContainer}
            >
              <div
                className={classes.videoBlock}
                style={this.state.videoBlockSize}
              >
                <div className={classes.remoteVideoStatus}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid item xs>
                      <Tooltip title="remote online">
                        <FiberManualRecordIcon
                          style={
                            this.state.isRemoteConnected
                              ? { fontSize: 20, color: "green" }
                              : { fontSize: 20, color: "red" }
                          }
                        />
                      </Tooltip>
                      {this.state.isRemoteConnected &&
                      this.state.isRemoteVideoStarted ? (
                        <Tooltip title="remote video is enable">
                          <VideocamIcon
                            style={{ fontSize: 20, color: "green" }}
                          />
                        </Tooltip>
                      ) : null}
                      {this.state.isRemoteConnected &&
                      !this.state.isRemoteVideoStarted ? (
                        <Tooltip title="remote video is disable">
                          <VideocamOffIcon
                            style={{ fontSize: 20, color: "red" }}
                          />
                        </Tooltip>
                      ) : null}
                      {this.state.isRemoteConnected &&
                      this.state.isRemoteAudioEnable ? (
                        <Tooltip title="remote volume is enable">
                          <VolumeUpIcon
                            style={{ fontSize: 20, color: "green" }}
                          />
                        </Tooltip>
                      ) : null}
                      {this.state.isRemoteConnected &&
                      !this.state.isRemoteAudioEnable ? (
                        <Tooltip title="remote volume is disable">
                          <VolumeOffIcon
                            style={{ fontSize: 20, color: "red" }}
                          />
                        </Tooltip>
                      ) : null}
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.localVideoBlock}>
                  <video
                    className={classes.localVideo}
                    style={
                      !this.state.isLocalVideoEnable ? { display: "none" } : {}
                    }
                    ref={this.localVideoRef}
                  />
                </div>
                <div className={classes.videoActionBlock}>
                  <Tooltip title="Refresh Video">
                    <IconButton
                      color="secondary"
                      aria-label="refresh"
                      onClick={this.reload}
                    >
                      <RefreshSharpIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Back to List">
                    <IconButton
                      color="secondary"
                      aria-label="back"
                      onClick={this.endVideo}
                    >
                      <ReplyAllIcon />
                    </IconButton>
                  </Tooltip>
                </div>
                <div
                  className={classes.remoteVideoBlock}
                  style={
                    this.state.isRemoteVideoStarted
                      ? { backgroundColor: "transparent" }
                      : {}
                  }
                >
                  {!this.state.isRemoteVideoStarted ? (
                    <div
                      className={`${classes.remoteLoading} ball-spin-fade-loader`}
                    >
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  ) : null}
                  <video
                    className={classes.remoteVideo}
                    style={
                      !this.state.isRemoteVideoStarted
                        ? { display: "none" }
                        : {}
                    }
                    ref={this.remoteVideoRef}
                  />
                </div>
              </div>
            </div>

            <div style={{ width: "2vw" }} />
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(withStyles(styles)(VideoPanel));
