import "./App.css";
import "./components/style.css";

import { Route, Switch, useLocation } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";

import CallCustomer from "./components/CallCustomer/CallCustomer";
import Main from "./components/MainView/Main";
import { getServingQueue } from "./components/BackendApi";
import { useWebSocket } from "./hooks/useWebsocket";

function App() {
  let location = useLocation();
  const {
    wsState,
    subscribe,
    unsubscribe,
    named,
    unnamed,
    registerListener,
    unRegisterListener,
  } = useWebSocket();
  const [checkinList, setCheckinList] = useState([]);
  const [eyetestDone, setEyetestDone] = useState(null); //'AT-4PU1AX'
  const [eyetestEmergency, setEyetestEmergency] = useState(null);
  const [eyetestHelp, setEyetestHelp] = useState(null);

  const storeId = useRef(null);

  const setStoreId = useCallback(
    (id) => {
      storeId.current = id;
      if (id) {
        named(id);
      } else {
        unnamed();
      }
    },
    [storeId, named, unnamed]
  );

  const fetchServingQueue = useCallback(() => {
    console.log("storeid", storeId);
    if (storeId.current) {
      getServingQueue(storeId.current)
        .then((result) => {
          setCheckinList(result);
        })
        .catch((error) => {
          console.error("Fetch Serving Queue Falied", error);
        });
    }
  }, [storeId, setCheckinList]);

  useEffect(() => {
    console.log("Init app");
    const kanbanUpgrade = (message) => {
      console.log("Receive Upgrade message:", message);
      window.location.reload();
    };

    const checkinUpdate = (message) => {
      console.log("Receive CheckIn Update Message:", message);
      if (
        message &&
        message.event_type &&
        ["finish", "emergency", "help", "clear"].includes(message.event_type)
      ) {
        if (message.event_type === "finish" && message.customer_no) {
          setEyetestDone(message.customer_no);
          setEyetestEmergency(null);
          setEyetestHelp(null);
        }
        if (message.event_type === "emergency" && message.customer_no) {
          setEyetestEmergency(message.customer_no);
          setEyetestDone(null);
          setEyetestHelp(null);
        }
        if (message.event_type === "help" && message.customer_no) {
          setEyetestHelp(message.customer_no);
          setEyetestEmergency(null);
          setEyetestDone(null);
        }
        if (message.event_type === "clear") {
          setEyetestEmergency(null);
          setEyetestDone(null);
          setEyetestHelp(null);
        }
      } else {
        fetchServingQueue();
      }
    };

    subscribe("kanban:upgrade", kanbanUpgrade);
    subscribe("store:checkin", checkinUpdate);

    fetchServingQueue();
    registerListener("onopen", fetchServingQueue);

    return () => {
      unsubscribe("kanban:upgrade", kanbanUpgrade);
      unsubscribe("store:checkin", checkinUpdate);

      unRegisterListener("onopen", fetchServingQueue);
    };
  }, [
    subscribe,
    unsubscribe,
    registerListener,
    unRegisterListener,
    fetchServingQueue,
  ]);

  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      <Switch location={location} key={location.key}>
        <Route path="/call/:data">
          <CallCustomer
            wsState={wsState}
            storeId={storeId}
            setStoreId={setStoreId}
            checkinList={checkinList}
            eyetestDone={eyetestDone}
            setEyetestDone={setEyetestDone}
            eyetestEmergency={eyetestEmergency}
            setEyetestEmergency={setEyetestEmergency}
            eyetestHelp={eyetestHelp}
            setEyetestHelp={setEyetestHelp}
          />
        </Route>
        <Route path="/">
          <Main
            wsState={wsState}
            storeId={storeId}
            setStoreId={setStoreId}
            checkinList={checkinList}
            fetchServingQueue={fetchServingQueue}
            subscribe={subscribe}
            unsubscribe={unsubscribe}
            registerListener={registerListener}
            unRegisterListener={unRegisterListener}
          />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
