import * as BackendApi from "../BackendApi";
import * as Utils from "../utils";

import { Component as ReactComponent, createRef } from "react";
import { createLocalAudioTrack, createLocalVideoTrack } from "twilio-video";

import Appointment from "../Appointment/Appointment";
import Checkin from "../Checkin/Checkin";
import Header from "../Header/Header";
import Login from "../Login/Login";
import VideoPanel from "../VideoPanel/VideoPanel";

const checkTokenMaxFailedCount = 3;

export default class Main extends ReactComponent {
  constructor(props) {
    super(props);
    this.fetchAppointmentsRunner = createRef();
    this.refreshAccessTokenRunner = createRef();
  }

  state = {
    username: "",
    password: "",

    loginState: false,

    logining: false,

    loginMessage: "",
    storeName: null,
    appointmentList: [],
    deviceList: [],
    availableOptometristCount: 0,

    onlineOptometristCount: 0,
    busyOptometristCount: 0,

    inVideo: false,
    videoSession: 0,

    detectedCamera: false,
    detectedAudio: false,
  };

  tokenMissedCount = 0;

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    this.setupStore();
  }

  componentWillUnmount() {
    this.storeLogout();
    this._isMounted = false;
    this.tokenMissedCount = 0;
  }

  deviceUpdate = (message) => {
    console.log("Receive Device Update Message:", message);
    this.fetchDeviceList();
  };

  fetchDeviceList = () => {
    if (this.props.storeId.current) {
      BackendApi.getDeviceList(this.props.storeId.current)
        .then((result) => {
          this.setState({ deviceList: result });
        })
        .catch((error) => {
          console.error("Fetch Device List Falied", error);
        });
    }
  };

  optometristStatusUpdate = (message) => {
    console.log("Receive Optometrist Status Update Message:", message);
    this.fetchOptometristCount();
  };

  fetchOptometristCount = () => {
    BackendApi.getOptometristStatistics()
      .then((result) => {
        let onlineCount = result.online_workers_count || 0;
        let busyCount = result.busy_workers_count || 0;
        let availableCount = result.available_workers_count || 0;
        this.setState({
          onlineOptometristCount: onlineCount,
          busyOptometristCount: busyCount,
          availableOptometristCount: availableCount,
        });
      })
      .catch((error) => {
        console.error("Fetch Optometrist Statistics Failed", error);
      });
  };

  fetchAppointmentList = () => {
    if (this.props.storeId.current) {
      BackendApi.getappointment(this.props.storeId.current)
        .then((result) => {
          this.setState({ appointmentList: result });
        })
        .catch((error) => {
          console.error("Fetch Device List Falied", error);
        });
    }
  };

  checkTokenAvailable = async () => {
    if (
      Utils.check_crm_access_token_expires_in() &&
      Utils.check_crm_access_token()
    ) {
      return true;
    }
    return this.refreshToken();
  };

  refreshToken = async () => {
    return BackendApi.fetch_access_token(
      localStorage.getItem("username"),
      localStorage.getItem("password")
    )
      .then((result) => {
        Utils.save_crm_access_token(result.data);
        console.log("Fetch Access Token Success:", result);
        return true;
      })
      .catch((error) => {
        console.error("Fetch Access Token Failed:", error);
        return false;
      });
  };

  checkTokenWillExpiresIn = async () => {
    if (Utils.check_crm_access_token_expires_in()) {
      this.tokenMissedCount = 0;
      return true;
    }
    console.warn("Token will expire in 1 minute. Try to refresh it.");
    let result = await this.refreshToken();
    if (result) {
      this.tokenMissedCount = 0;
    } else {
      this.tokenMissedCount = this.tokenMissedCount + 1;
      console.error(
        `Refresh access token failed. count: ${this.tokenMissedCount}`
      );
      if (this.tokenMissedCount >= checkTokenMaxFailedCount) {
        console.error(
          `Maximum number of refresh access token failures reached. Go login page.`
        );
        this.setState({
          loginMessage:
            `${checkTokenMaxFailedCount} automatic login attempts have failed, please log in manually by click.`,
        });
        this.storeLogout();
      }
    }
  };

  storeLogin = (storeId, storeName) => {
    this.props.setStoreId(storeId);
    this.props.subscribe("store:device", this.deviceUpdate);
    this.props.registerListener("onopen", this.fetchDeviceList);
    let optometry_channel =
      process.env.REACT_APP_OPTOMETRY_CHANNEL ||
      process.env.REACT_APP_SERVICE_CODE ||
      "unknow";
    this.props.subscribe(
      `${optometry_channel.toLowerCase()}:optometrist:status`,
      this.optometristStatusUpdate
    );
    this.props.registerListener("onopen", this.fetchOptometristCount);

    this.setState({
      storeName: storeName,
      loginState: true,
    });
    this.fetchDeviceList();
    this.fetchOptometristCount();

    this.fetchAppointmentList();
    this.props.fetchServingQueue();

    this.fetchAppointmentsRunner.current = setInterval(
      () => this.fetchAppointmentList(),
      (60 + Math.floor(Math.random() * (60 + 1))) * 1000
    );

    this.refreshAccessTokenRunner.current = setInterval(
      () => this.checkTokenWillExpiresIn(),
      10 * 1000
    );
  };

  storeLogout = () => {
    this.props.setStoreId(null);
    this.props.unRegisterListener("onopen", this.fetchDeviceList);
    this.props.unsubscribe("store:device", this.deviceUpdate);
    this.props.unRegisterListener("onopen", this.fetchOptometristCount);
    let optometry_channel =
      process.env.REACT_APP_OPTOMETRY_CHANNEL ||
      process.env.REACT_APP_SERVICE_CODE ||
      "unknow";
    this.props.unsubscribe(
      `${optometry_channel.toLowerCase()}:optometrist:status`,
      this.optometristStatusUpdate
    );

    this.setState({
      storeName: null,
      loginState: false,
    });

    if (this.fetchAppointmentsRunner.current) {
      clearInterval(this.fetchAppointmentsRunner.current);
    }

    if (this.refreshAccessTokenRunner.current) {
      clearInterval(this.refreshAccessTokenRunner.current);
    }

  };

  setupStore = async () => {
    this.detectCamera();
    this.detectAudio();
    let result = await this.checkTokenAvailable();
    console.log("Login result:", result);
    if (!result) {
      this.setState({ loginMessage: "Login please." });
      return;
    }

    this.setState({ logining: true });

    return BackendApi.getstore()
      .then((store) => {
        let storeId = store.store_no || null;
        let storeName = store.store_name || "";
        this.storeLogin(storeId, storeName);
        this.setState({ logining: false });
      })
      .catch((error) => {
        console.log("Fetch Store Info Failed:", error);
        if (error.code === 401) {
          this.setState({
            loginMessage:
              "The login information has expired, please login again.",
          });
        } else if (error.data?.error_message?.code === 40701) {
          this.setState({
            loginMessage: "Login failed. User is not found in system.",
          });
        } else if (error.data?.error_message?.code === 40702) {
          this.setState({
            loginMessage: "Login failed. This account isn't a store account.",
          });
        } else {
          this.setState({
            loginMessage: "Service timeout. Please try again later.",
          });
        }
        this.setState({ logining: false });
      });
  };

  save_login_storage = () => {
    localStorage.setItem("username", this.state.username);
    localStorage.setItem("password", this.state.password);
  };

  clear_login_storage = () => {
    localStorage.removeItem("username");
    localStorage.removeItem("password");
  };

  login = () => {
    if(!this.state.username || !this.state.password){
      this.setState({
        loginMessage: "Please type the account and password.",
      });
      return
    }
    this.setState({ logining: true, loginMessage: ""});
    BackendApi.fetch_access_token(this.state.username, this.state.password)
      .then((result) => {
        Utils.save_crm_access_token(result.data);
        this.save_login_storage();
        this.setupStore();
      })
      .catch((error) => {
        console.log("Fetch Access Token Failed:", error);
        if (error.code === 401) {
          this.setState({
            loginMessage: "Login falied. Incorrect account or password.",
          });
        } else {
          this.setState({
            loginMessage: "Service timeout. Please try again later.",
          });
        }
        this.setState({ logining: false });
      });
  };

  setUsername = (username) => {
    this.setState({ username: username });
  };

  setPassword = (password) => {
    this.setState({ password: password });
  };

  logout = () => {


    Utils.clear_login_cookie();
    this.clear_login_storage();

    this.storeLogout();
  };

  startVideo = (sessionId) => {
    console.log("Start video with session ", sessionId);
    if (sessionId) {
      this.setState({
        inVideo: true,
        videoSession: sessionId,
      });
    }
  };

  endVideo = () => {
    this.setState({
      inVideo: false,
      videoSession: 0,
    });
  };

  detectCamera = async () => {
    try {
      let videoTrack = await createLocalVideoTrack({ width: 320 });
      videoTrack.stop();
      if (this._isMounted) {
        this.setState({ detectedCamera: true });
        console.log("Detected camera.", videoTrack);
      } else {
        console.warn("Detected camera after Unmounted", videoTrack);
      }
    } catch (error) {
      console.error("Can't detect camera.");
      console.table(error);
    }
  };

  detectAudio = async () => {
    try {
      let audioTrack = await createLocalAudioTrack();
      audioTrack.stop();
      if (this._isMounted) {
        this.setState({ detectedAudio: true });
        console.log("Detected audio.", audioTrack);
      } else {
        console.warn("Detected audio after Unmounted", audioTrack);
      }
    } catch (error) {
      console.error("Can't detect audio.");
      console.table(error);
    }
  };

  render() {
    return (
      <>
        <div className="App">
          <div
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <Header
              wsState={this.props.wsState}
              onLogout={this.logout}
              loginState={this.state.loginState}
              storeName={this.state.storeName}
              inVideo={this.state.inVideo}
            />
            {this.state.loginState ? (
              <div style={{ flex: 1 }}>
                {this.state.inVideo ? (
                  <VideoPanel
                    videoSession={this.state.videoSession}
                    endVideo={this.endVideo}
                  />
                ) : (
                  <div>
                    <Appointment
                      appointmentList={this.state.appointmentList}
                      deviceList={this.state.deviceList}
                      detectedCamera={this.state.detectedCamera}
                      detectedAudio={this.state.detectedAudio}
                    />
                    <Checkin
                      checkinList={this.props.checkinList}
                      availableOptometristCount={
                        this.state.availableOptometristCount
                      }
                      onlineOptometristCount={this.state.onlineOptometristCount}
                      busyOptometristCount={this.state.busyOptometristCount}
                      startVideo={this.startVideo}
                    />
                  </div>
                )}
              </div>
            ) : (
              <Login
                login={this.login}
                username={this.state.username}
                password={this.state.password}
                loginMessage={this.state.loginMessage}
                setUsername={this.setUsername}
                setPassword={this.setPassword}
                logining={this.state.logining}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}
