import React from "react";

class Login extends React.Component {
  componentDidMount() {
    let u = localStorage.getItem("username");
    let w = localStorage.getItem("password");
    if (u) {
      this.props.setUsername(u);
    }
    if (w) {
      this.props.setPassword(w);
    }
  }

  back = () => {
    window.history.back();
  };

  render() {
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <div style={{ height: "10vh" }}></div>
        <div className={"login"}>
          <h1 className={"login-title"}>Sign In</h1>
          <input
            className={"username form-control"}
            type={"text"}
            placeholder={"Account"}
            name={"login"}
            required={"required"}
            autoCapitalize="off"
            value={this.props.username}
            onChange={(e) => this.props.setUsername(e.target.value)}
          />
          <input
            className={"password form-control"}
            type={"password"}
            placeholder={"Password"}
            name={"password"}
            required={"required"}
            autoComplete={"current-password"}
            maxLength={"4096"}
            value={this.props.password}
            onChange={(e) => this.props.setPassword(e.target.value)}
          />
          <button
            className={"login-submit"}
            onClick={this.props.login}
            disabled={this.props.logining}
          >
            Sign In
          </button>
          {sessionStorage.getItem("showBack") ? (
            <button className={"login-back"} onClick={this.back}>
              Back
            </button>
          ) : null}
          <div id={"login-result"}>{this.props.loginMessage}</div>
        </div>
      </div>
    );
  }
}

export default Login;
