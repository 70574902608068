import * as Utils from "./utils";

import axios from "axios";
import querystring from "querystring";

let apiSetting = {
  service_country: process.env.REACT_APP_SERVICE_CODE,
  baseURL: process.env.REACT_APP_SERVICE_ENDPOINT,
};

let backend = axios.create({
  baseURL: apiSetting.baseURL,
});
backend.defaults.timeout = 100000;

backend.interceptors.request.use((config) => {
  config.headers["Authorization"] = Utils.check_crm_access_token();

  return config;
});

backend.interceptors.response.use(
  (response) => {
    // 对响应数据做点什么
    return Promise.resolve({
      success: true,
      code: 200,
      data: response.data.data,
    });
  },
  (error) => {
    // 对响应错误做点什么
    if (error.response) {
      if (401 === error.response.status) {
        // Utils.openLogin()
        // Utils.errorTips('crm:message.loginInfoIsExpirePleaseRelogin', true)
      }
      return Promise.reject({
        success: false,
        code: error.response.status,
        data: error.response.data,
      });
    }
    return Promise.reject({
      success: false,
      code: 500,
      data: null,
    });
  }
);

export const fetch_access_token = (username, password) => {
  return axios
    .post(
      apiSetting.baseURL + "/auth/access_token",
      querystring.stringify({
        username: username,
        password: password,
        grant_type: "password",
        country_code: apiSetting.service_country,
      })
    )
    .then((response) => ({
      success: true,
      code: 200,
      data: response.data,
    }))
    .catch((error) => {
      if (error.response) {
        return Promise.reject({
          success: false,
          code: error.response.status,
          data: error.response.data,
        });
      }
      return Promise.reject({
        success: false,
        code: 500,
        data: null,
      });
    });
};

export const logout = () => {
  return backend.post("/auth/logout");
};

export const fetch_currenr_user = () => {
  return backend.get("/api_v2/user/me");
};
export const getstore = () => {
  return backend.get("/api_v2/user/store").then((result) => {
    return result.data;
  });
};
export const getvideo = (session_id) => {
  return backend
    .post("/api_v2/check_in/optometry/video/start", { session_id: session_id })
    .then((result) => result.data);
};
export const invite_optometrist = (body) => {
  return backend
    .post("/api_v2/check_in/optometry/video/invite_optometrist", body)
    .then((result) => result.data);
};

export const getAvailableOptometristCount = () => {
  return backend
    .get("/api_v2/check_in/optometry/available/optometrist")
    .then((result) => result.data);
};

export const getOptometristStatistics = () => {
  let optometry_channel =
    process.env.REACT_APP_OPTOMETRY_CHANNEL ||
    process.env.REACT_APP_SERVICE_CODE ||
    "unknow";
  return backend
    .get(
      `/api_v2/check_in/optometry/realtime/statistics?channel=${optometry_channel.toLowerCase()}`
    )
    .then((result) => result.data);
};

export const getcheckin = (store_no) => {
  return backend.get("/api_v2/check_in/list/pending?store_no=" + store_no);
};

export const getServingQueue = (store_no) => {
  return backend.get(`/api_v1/check_in/pending/${store_no}`).then((result) => {
    return result.data.filter((item) => !item.prescriptions);
  });
};

export const getappointment = (store_no) => {
  return backend
    .get("/api_v2/store/appointments/normal/today?store_no=" + store_no)
    .then((result) => result.data);
};
export const postqueue = (session_id) => {
  return backend.post("/api_v2/check_in/optometry/serve", {
    session_id: session_id,
  });
};

export const getDeviceList = (store_no) => {
  return backend
    .get("/api_v2/store/search/device_status/" + store_no)
    .then((result) => {
      return result.data;
    });
};
