import { green, red, yellow } from "@material-ui/core/colors";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import MoodBadIcon from "@material-ui/icons/MoodBad";
import React from "react";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    textAlign: "center",
    backgroundColor: "#000000",
    color: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  title: {
    fontSize: 60,
    fontWeight: 900,
  },
  dataTable: {
    width: "100%",
    marginTop: 20,
    "& th": {
      fontSize: 25,
    },
    "& td": {
      fontSize: 26,
    },
    borderTop: "1px solid",
    "& td, & th": {
      padding: "4px 8px",
      fontFamily: "Verdana",
      borderLeft: "1px solid",
      borderBottom: "1px solid",
    },
    "& td.last, & th.last": {
      borderRight: "1px solid",
    },
  },
  defaultTableColor: {
    "& td, & th": {
      color: "#ffffff",
    },

    "& tr.hightLigt>th": {
      color: "#5dc11f",
      borderColor: "#ffffff",
    },

    "& tr.hightLigt>td": {
      color: "#5dc11f",
      borderColor: "#ffffff",
      fontWeight: 750,
    },
  },
  hilightTableColor: {
    borderTop: "2px solid #5dc11f",
    "& td, & th": {
      padding: "2px 6px",
      borderLeft: "2px solid #5dc11f",
      borderBottom: "2px solid #5dc11f",
    },
    "& td.last, & th.last": {
      borderRight: "2px solid #5dc11f",
    },

    "& tr.hightLigt>th": {
      color: "#5dc11f",
    },

    "& tr.hightLigt>td": {
      color: "#5dc11f",
      fontWeight: 900,
    },
  },
  helpTableColor: {
    borderTop: "2px solid yellow",
    "& td, & th": {
      padding: "2px 6px",
      borderLeft: "2px solid yellow",
      borderBottom: "2px solid yellow",
    },
    "& td.last, & th.last": {
      borderRight: "2px solid yellow",
    },

    "& tr.hightLigt>th": {
      color: "yellow",
    },

    "& tr.hightLigt>td": {
      color: "yellow",
      fontWeight: 900,
    },
  },
  nowServing: {
    fontSize: 60,
  },
  queueNumber: {
    paddingTop: 60,
    fontSize: 135,
  },

  hightLigt: {
    color: "#5dc11f",
  },
  emergency: {
    color: "red",
  },
  yellow: {
    color: yellow[500],
  },
  emergencyBackdrop: {
    zIndex: 1,
    backgroundColor: "rgb(255 31 31 / 65%)",
  },

  flashing: {
    webkitAnimation: "flashing 1s infinite",
    animation: "flashing 1s infinite",
  },

  borderFlashing: {
    webkitBoxSizing: "border-box",
    boxSizing: "border-box",
    border: "solid 15px",
    webkitAnimation: "borderFlashing 1s infinite",
    animation: "borderFlashing 1s infinite",
  },
  greenBorder: {
    borderColor: "#5dc11f",
  },
  yellowBorder: {
    borderColor: "yellow",
  },

  wsState: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    position: "absolute",
    color: "white",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",

    fontSize: "1.5vw",
  },

  connected: {
    color: green[500],
  },

  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },

  connecting: {
    color: yellow[500],
  },

  moodBad: {
    color: red[500],
  },

  connectingLoading: {
    color: yellow[500],
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1,
  },
});

const generateServingData = (props) => {
  let show_list = [];
  let servingNumber = null;
  let servingCustomer = null;
  if (props.checkinList) {
    for (let i = 0; i < 15; i++) {
      let item = null;
      if (i < props.checkinList.length) {
        item = props.checkinList[i];
      } else {
        item = {
          customer_ref: "",
          sequence_num: "",
          now_serving: false,
        };
      }
      if (item.now_serving) {
        servingNumber = item.sequence_num;
        servingCustomer = item.customer_ref;
      }
      show_list.push(item);
    }
  }
  return {
    list: show_list,
    servingNumber: servingNumber,
    servingCustomer: servingCustomer,
  };
};
class CallCustomer extends React.Component {
  state = {
    servingData: {},
  };

  componentDidMount() {
    let store_id = this.props.match.params.data;
    if (store_id) {
      this.props.setStoreId(store_id);
    }
  }

  componentWillUnmount() {
    if (this.props.storeId.current) {
      this.props.setStoreId(null);
    }
  }

  static getDerivedStateFromProps(props, state) {
    return {
      servingData: generateServingData(props),
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.eyetestDone &&
      this.props.eyetestDone !== this.state.servingData.servingCustomer
    ) {
      this.props.setEyetestDone(null);
    }

    if (
      this.props.eyetestEmergency &&
      this.props.eyetestEmergency !== this.state.servingData.servingCustomer
    ) {
      this.props.setEyetestEmergency(null);
    }

    if (
      this.props.eyetestHelp &&
      this.props.eyetestHelp !== this.state.servingData.servingCustomer
    ) {
      this.props.setEyetestHelp(null);
    }
  }

  render() {
    const { classes, eyetestDone, eyetestEmergency, eyetestHelp } = this.props;
    let currentDone =
      this.state.servingData.servingCustomer &&
      eyetestDone === this.state.servingData.servingCustomer;
    let currentEmergency =
      this.state.servingData.servingCustomer &&
      eyetestEmergency === this.state.servingData.servingCustomer;
    let currentHelp =
      this.state.servingData.servingCustomer &&
      eyetestHelp === this.state.servingData.servingCustomer;
    let serviceCode =
      (process.env.REACT_APP_SERVICE_CODE &&
        process.env.REACT_APP_SERVICE_CODE.toLowerCase()) ||
      "";
    let german = serviceCode.startsWith("at") || serviceCode.startsWith("de");
    return (
      <>
        <Backdrop
          className={classes.emergencyBackdrop}
          open={currentEmergency ? true : false}
        >
          <WarningRoundedIcon style={{ fontSize: 150, color: yellow[500] }} />
          <span style={{ fontSize: 60, color: yellow[500] }}>
            {german ? "Unterstützung benötigt" : "Assistance required"}
          </span>
        </Backdrop>
        <div
          className={`
          ${classes.root}
          ${currentDone || currentHelp ? classes.borderFlashing : ""} 
          ${currentDone ? classes.greenBorder : ""}
          ${currentHelp ? classes.yellowBorder : ""}
          `}
        >
          <div
            className={`
            ${classes.wsState} 
            ${this.props.wsState === "connected" ? classes.connected : ""}
            ${this.props.wsState === "connecting" ? classes.connecting : ""}
            ${this.props.wsState === "closed" ? classes.moodBad : ""}`}
          >
            {this.props.wsState === "connected" ? (
              <VerifiedUserIcon />
            ) : (
              <div className={classes.wrapper}>
                <MoodBadIcon className={classes.moodBad} />
                {this.props.wsState === "connecting" ? (
                  <CircularProgress
                    size={24}
                    className={classes.connectingLoading}
                  />
                ) : null}
              </div>
            )}
            <span>{this.props.wsState}</span>
          </div>
          <div className={"queue"}>
            <div
              className={`${classes.title} ${
                currentHelp || currentEmergency
                  ? classes.yellow
                  : classes.hightLigt
              }`}
            >
              Queue
            </div>
            <table
              className={`
              ${classes.dataTable}
              ${
                currentHelp || currentEmergency
                  ? classes.helpTableColor
                  : currentDone
                  ? classes.hilightTableColor
                  : classes.defaultTableColor
              }
            `}
            >
              <thead>
                <tr className={currentEmergency ? "emergency" : "hightLigt"}>
                  <th width={"40px"}>ID</th>
                  <th>Customer No.</th>
                  <th className="last">Queue No.</th>
                </tr>
              </thead>
              <tbody>
                {this.state.servingData.list &&
                  this.state.servingData.list.map((item, index) => {
                    return (
                      <tr
                        key={index + 1}
                        className={`${item.now_serving ? "hightLigt" : ""} 
                          ${
                            item.now_serving &&
                            (currentDone || currentEmergency || currentHelp)
                              ? classes.flashing
                              : ""
                          } 
                        `}
                      >
                        <td>{index + 1}</td>
                        <td>{item.customer_ref}</td>
                        <td className="last">{item.sequence_num}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>

          <div className={"call-customer"}>
            <img
              className={"queue-logo"}
              src={process.env.REACT_APP_LOGO}
              alt=""
            />
            {this.state.servingData.servingNumber ? (
              <>
                <div
                  className={`${classes.nowServing}
                  ${currentDone ? classes.hightLigt : ""} 
                  ${currentHelp || currentEmergency ? classes.yellow : ""} 
                  ${
                    currentDone || currentEmergency || currentHelp
                      ? classes.flashing
                      : ""
                  }
                  `}
                >
                  Now Serving
                </div>
                <div
                  className={`${classes.queueNumber}
                  ${currentDone ? classes.hightLigt : ""} 
                  ${currentHelp || currentEmergency ? classes.yellow : ""} 
                  ${
                    currentDone || currentEmergency || currentHelp
                      ? classes.flashing
                      : ""
                  }
                `}
                >
                  {this.state.servingData.servingNumber}
                </div>
              </>
            ) : null}
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(withStyles(styles)(CallCustomer));
