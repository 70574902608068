import * as BackendApi from "../BackendApi";

import React from "react";
import notification from "../../image/notification.png";
import smartphone from "../../image/video-camera.png";

let hight_ligt = {
  color: "#5dc11f",
};

class Checkin extends React.Component {
  post_queue = (id) => {
    BackendApi.postqueue(id)
      .then((result) => {
        document.getElementById("call_queue_result").innerHTML =
          "Already called successfully!";
      })
      .catch((error) => {
        document.getElementById("call_queue_result").innerHTML = "Call failed";
      });
  };

  generateServingView = () => {
    let viewList = [];
    for (let i = 0; i < 15; i++) {
      if (i < this.props.checkinList.length) {
        let item = this.props.checkinList[i];
        viewList.push(
          <tr key={i + 1}>
            <td style={item.now_serving ? hight_ligt : null}>{i + 1}</td>
            <td style={item.now_serving ? hight_ligt : null}>
              {item.customer_ref}
            </td>
            <td style={item.now_serving ? hight_ligt : null}>
              {item.start_date}
            </td>
            <td style={item.now_serving ? hight_ligt : null}>
              {item.sequence_num}
              <span
                onClick={() => this.post_queue(item.id)}
                className="home_button"
                style={{ width: "80px", marginLeft: "20px" }}
              >
                <img className={"notification"} src={notification} alt="" />
              </span>
            </td>
            {item.now_serving ? (
              <td style={hight_ligt}>
                <span onClick={()=>{this.props.startVideo(item.id)}} className="home_button" style={{ width: "80px" }}>
                  <img className={"smartphone"} src={smartphone} alt="" />
                </span>
              </td>
            ) : (
              <td>{""}</td>
            )}
          </tr>
        );
      } else {
        viewList.push(
          <tr key={i + 1}>
            <td>{i + 1}</td>
            <td>{""}</td>
            <td>{""}</td>
            <td>{""}</td>
            <td>{""}</td>
          </tr>
        );
      }
    }
    return viewList;
  };

  showOptometristStatus = (value, status) => {
    if (!value || value <= 0) {
      return <span style={{ color: "#ff0000" }}>no optometrist {status}</span>;
    } else if (value === 1) {
      return `1 optometrist ${status}`;
    } else {
      return `${value} optometrists ${status}`;
    }
  };

  render() {
    return (
      <>
        <div className={"checkin"}>
          <div>
            <div className={"checkin-name"}>Check-In</div>

            <span
              style={{ position: "absolute", right: "20px", color: "#5dc11f" }}
            >
              {this.showOptometristStatus(
                this.props.onlineOptometristCount,
                "online"
              )}
              ,
              {this.showOptometristStatus(
                this.props.busyOptometristCount,
                "in task"
              )}
              ,
              {this.showOptometristStatus(
                this.props.availableOptometristCount,
                "available"
              )}
            </span>
          </div>
          <table className={"checkin-content"} id={"table"}>
            <tbody>
              <tr>
                <th width={"40px"}>ID</th>
                <th>Customer No.</th>
                <th>Check-In</th>
                <th>Queue No.</th>
                <th>Option</th>
              </tr>
              {this.generateServingView()}
            </tbody>
          </table>
          <div id={"call_queue_result"}></div>
        </div>
      </>
    );
  }
}

export default Checkin;
