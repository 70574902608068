import "./index.css";

import App from "./App";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

let query = new URLSearchParams(window.location.search);
let user = query.get("p");
let pwd = query.get("w");
let back = query.get("b");
let needRefresh = false;
if (user && pwd) {
  localStorage.setItem("username", user);
  localStorage.setItem("password", pwd);
  needRefresh = true;
}
if (back) {
  sessionStorage.setItem("showBack", 1);
  needRefresh = true;
}
if (needRefresh) {
  window.location.href = window.location.protocol + "//" + window.location.host;
}

ReactDOM.render(
  <React.Fragment>
    <Router>
      <App />
    </Router>
  </React.Fragment>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
