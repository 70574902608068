import { useCallback, useEffect, useRef, useState } from 'react';

export const readyState = ['UNOPEN', 'OPEN', 'CLOSING', 'CLOSED']

export const useWebSocket = ({
        keepAliveTimeout = 10, // seconds of keep alive timeout
        maxHeartbeatCount=3,
    } = {}) => {
    const ws = useRef(null)
    const unmount = useRef(false)
    const subscribeChannel = useRef({})
    const clientName = useRef(null)
    
    const onOpenListener = useRef([])

    // closed, connecting, connected
    const [wsState, setWsState] = useState('closed')

    const heartbeatCount = useRef(0)
    const heartbeatRunner = useRef(null)

    const reconnectingRunner = useRef(null)

    const registerListener = useCallback((event, func) => {
        if (event === 'onopen') {
            onOpenListener.current.push(func)
        }
        console.log(`[${new Date().toISOString()}] - Regist ${event} event listener, current listener: `, onOpenListener.current)
    }, [])

    const unRegisterListener = useCallback((event, func) => {
        if (event === 'onopen') {
            let index = onOpenListener.current.indexOf(func)
            if (index >= 0) {
                onOpenListener.current.splice(index, 1)
            }
        }
        console.log(`[${new Date().toISOString()}] - Unregist ${event} event listener, current listener: `, onOpenListener.current)
    }, [])

    const sendMessage = useCallback(message => {
        if (ws.current && readyState[ws.current.readyState] === 'OPEN') {
            ws.current.send(message);
            return true
        }
        return false
    }, []);

    const ping = useCallback(() => {
        let result = sendMessage(JSON.stringify({ type: "ping" }))
        console.log(`[${new Date().toISOString()}] - Ping ws server. Send result: ${result}`)
        return result
    }, [sendMessage])

    const pong = useCallback(() => {
        let result = sendMessage(JSON.stringify({ type: "pong" }))
        console.log(`[${new Date().toISOString()}] - Response pong to ws server. Send result: ${result} `)
        return result
    }, [sendMessage])

    const subscribe = useCallback((channel, func) => {
        let calls = subscribeChannel.current[channel]
        if (calls) {
            calls.push(func)
        } else {
            subscribeChannel.current[channel] = [func]
            sendMessage(JSON.stringify({ type: "subscribe", channels: [channel] }))
        }
        console.log(`[${new Date().toISOString()}] - Cueernt subscribed channel: `, subscribeChannel.current)
    }, [sendMessage])

    const unsubscribe = useCallback((channel, func) => {
        let calls = subscribeChannel.current[channel]
        if (calls) {
            let index = calls.indexOf(func)
            if (index >= 0) {
                calls.splice(index, 1)
            }
            if (calls.length === 0) {
                delete subscribeChannel.current[channel]
                sendMessage(JSON.stringify({ type: "unsubscribe", channels: [channel] }))
            }
        }
        console.log(`[${new Date().toISOString()}] - Unsubscribe ${channel}. Cueernt subscribed channel: `, subscribeChannel.current)
    }, [sendMessage])

    const named = useCallback((name) => {
        if (name) {
            clientName.current = name
            sendMessage(JSON.stringify({ type: "name", name: name }))
            console.log(`[${new Date().toISOString()}] - Named current connection with ${name}`)
        }
    }, [sendMessage])

    const unnamed = useCallback(() => {
        if (clientName.current) {
            console.log(`[${new Date().toISOString()}] - Unnamed current connection name ${clientName.current} to null`)
            clientName.current = null
            sendMessage(JSON.stringify({ type: "unname" }))
        }
    }, [sendMessage])

    useEffect(() => {
        const connectWS = () => {
            setWsState('connecting')
            console.log(`[${new Date().toISOString()}] - Try Connect to WS Server`)
            let ep = process.env.REACT_APP_SERVICE_ENDPOINT || ''
            let ws_ep = ep.replace('http', 'ws')
            ws.current = new WebSocket(`${ws_ep}/pubsub/ws`)
            console.log(`[${new Date().toISOString()}] - Create WS Connection ${ws.current}`)
            ws.current.onopen = (event) => {
                setWsState('connected')
                console.log(`[${new Date().toISOString()}] - Connected to ws server. Open Subscribe Cahnnel`, event)
                let channels = Object.keys(subscribeChannel.current)
                if (channels.length > 0) {
                    sendMessage(JSON.stringify({ type: "subscribe", channels: channels }))
                }
                if (clientName.current) {
                    sendMessage(JSON.stringify({ type: "name", name: clientName.current }))
                }
                onOpenListener.current.forEach(func => {
                    func()
                })
                startHeartbeat()
            }
            ws.current.onmessage = (event) => {
                try {
                    let msg = JSON.parse(event.data)
                    console.log(`[${new Date().toISOString()}] - Receive Message`, msg)
                    if (msg.type === "publish") {
                        let calls = subscribeChannel.current[msg.channel]
                        if (calls && calls.length > 0) {
                            calls.forEach(func => {
                                func(msg.message)
                            });
                        }
                    }else if (msg.type === 'ping'){
                        console.info(`[${new Date().toISOString()}] - Receive ping message from ws server`, msg)
                        pong()
                    }else if (msg.type === 'pong'){
                        console.info(`[${new Date().toISOString()}] - Receive pong message from ws server`, msg)
                        if(heartbeatRunner.current){
                            resetHeartbeatCount()
                        }
                        // if(aliveConfirmTimeoutRunner.current){
                        //     confirmCurrentAlive()
                        // }
                    }
                } catch {
                    console.error(`[${new Date().toISOString()}] - Can not decode message event: `, event)
                }
            }

            ws.current.onclose = (event) => {
                setWsState('closed')
                stopHeartbeat()
                console.log(`[${new Date().toISOString()}] - Subscribe Cahnnel Closed. Wait 1 second, it will be reopened.`)
                reconnectingRunner.current = setTimeout(() => {
                    reconnectingRunner.current = null
                    reconnectWS({closed: true})
                }, 1000);
            }
        }

        const closeWS = async(currentWs) => {
            console.log(`[${new Date().toISOString()}] - Try Close WS Connection ${currentWs}`)
            currentWs?.close()
            return true
        }

        const cleanCurrentWS = ({closed=false} = {}) => {
            console.log(`[${new Date().toISOString()}] - Clean Current WS Connection immediately. Current connection is closed? ${closed}`)
            ws.current && (ws.current.onclose = () => {})
            if(!closed){
                setWsState('closed')
                stopHeartbeat()
                closeWS(ws.current)
            }
            ws.current = null
        }

        const reconnectWS = ({closed=false} = {}) => {
            if(reconnectingRunner.current){
                console.log(`[${new Date().toISOString()}] - Already trigger reconnect by current connection, please waitting it to reopen.`)
            }else{
                console.log(`[${new Date().toISOString()}] - Do reconnect to WS server`)
                cleanCurrentWS({closed: closed})
                connectWS()
            }
        }

        const resetHeartbeatCount = () => {
            console.log(`[${new Date().toISOString()}] - Reset current heartbeat count from ${heartbeatCount.current} to 0`)
            heartbeatCount.current = 0
        }

        const startHeartbeat = () => {
            console.log(`[${new Date().toISOString()}] - Start heartbeat runner to check whether the current connection is alive. Keep alive time: ${keepAliveTimeout} seconds.`)
            heartbeatRunner.current = setInterval(() => {
                if(heartbeatCount.current >= maxHeartbeatCount){
                    console.warn(`[${new Date().toISOString()}] - Did not receive heartbeat confirmation ${heartbeatCount.current} times in a row. Current connection is lost.`)
                    reconnectWS()
                }else{
                    let result = ping()
                    if(result){
                        heartbeatCount.current = heartbeatCount.current + 1
                        console.log(`[${new Date().toISOString()}] - Do heartbeat check. count: ${heartbeatCount.current}`)
                    }else{
                        console.error(`[${new Date().toISOString()}] - Do heartbeat check failed. Detected connection is lost.`)
                        reconnectWS()
                    }
                }
            }, keepAliveTimeout * 1000);
        }

        const stopHeartbeat = () => {
            clearInterval(heartbeatRunner.current)
            heartbeatRunner.current = null
            heartbeatCount.current = 0
        }

        connectWS()
        return () => {
            unmount.current = true
            clientName.current = null
            subscribeChannel.current = {}
            cleanCurrentWS()
        };
    }, [ws, unmount, sendMessage, ping, pong, maxHeartbeatCount, keepAliveTimeout]);

    return {
        wsState,
        subscribe,
        unsubscribe,
        named,
        unnamed,
        subscribeChannel,
        registerListener,
        unRegisterListener,
    }
}